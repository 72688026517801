import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { History } from "react-router-dom";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";
import FormContainer from "@ats/src/components/forms/FormContainer";
import FormSelect from "@ats/src/components/forms/FormSelect";

import { useModalContext } from "@shared/context/ModalContext";
import { useToastContext } from "@shared/context/ToastContext";
import { nextLogical } from "@ats/src/lib/utils/helpers";

import { useBulkMoveJobApplicationsToStage } from "@shared/queryHooks/useJobApplication";
import { HiringStage } from "@ats/types/job";

type Props = {
  jobId: number;
  onCancel: ({}) => void;
  currentStage: HiringStage;
  hiringStages: Array<HiringStage>;
  idsArray: Array<number>;
  idsArrayType: string;
  candidatesCount: number;
  firstRemainingJobApplicationUrl: string | boolean;
  resetList: () => void;
  history: History;
};

function BulkMoveModal(props: Props) {
  const {
    jobId,
    onCancel,
    currentStage,
    hiringStages,
    idsArray,
    idsArrayType,
    candidatesCount,
    firstRemainingJobApplicationUrl,
    history,
    resetList
  } = props;
  const addToast = useToastContext();
  const nextLogicalHiringStage = nextLogical({
    list: hiringStages.filter((stage) => stage.kind !== "kind_archived"),
    currentItem: currentStage,
  });
  const [errors, setErrors] = React.useState([]);
  const [stageId, setStageId] = React.useState(
    nextLogicalHiringStage ? nextLogicalHiringStage.id : currentStage.id,
  );

  const {
    mutate: moveBulkJobApplicationsToStage,
    isLoading: isLoadingMoveBulkJobApplicationsToStage,
  } = useBulkMoveJobApplicationsToStage();

  const { dismissModalWithAnimation } = useModalContext();
  const hiringStageOptions = hiringStages
    ? hiringStages
        .filter((stage) => stage !== currentStage) // && stage.kind !== "kind_archived")
        .map((hiringStage) => ({ value: hiringStage.id, label: hiringStage.name }))
    : [];
  const targetStageUrl = `/jobs/${jobId}/stages/${stageId}/applicants`
  const targetStageName = hiringStageOptions.find(option => option.value === stageId)?.label;

  const handleOnCancel = (event) => {
    if (event != undefined) {
      event.preventDefault();
    }
    dismissModalWithAnimation(() => onCancel);
  };

  const handleOnConfirm = (event) => {
    event.preventDefault();

    handleConfirmation();
    // dismissModalWithAnimation(() => handleConfirmation); // have to call the function like this so that onConfirm does not run immediately
  };

  const handleConfirmation = () => {
    window.logger(
      "%c[BulkMoveModal] handleConfirmation",
      "background-color: #197600; color: #FFF;",
      {
        stageId,
        firstRemainingJobApplicationUrl,
        idsArrayType,
        idsArray,
      },
    );

    moveBulkJobApplicationsToStage(
      {
        jobId,
        excludedJobApplicationIds: idsArrayType === "excluded" ? idsArray : [],
        includedJobApplicationIds: idsArrayType === "included" ? idsArray : [],
        targetHiringStageId: stageId,
        sourceHiringStageId: currentStage.id,
      },
      {
        onSuccess: (data) => {
          window.logger("%c[BulkMoveModal] onSuccess", "color: #19d232", { data });
          
          addToast({
            title: `Moved ${candidatesCount} candidate${candidatesCount === 1 ? "" : "s"} to ${targetStageName}`,
            kind: "success",
            delay: 6000,
            linkTo: targetStageUrl,
          });
          resetList();
          moveFocusToFirstRemainingJobApplication();
          dismissModalWithAnimation(() => onCancel);
        },

        onError: (response) => {
          window.logger("%c[BulkMoveModal] onError", "color: #d28819", { response });
          // addToast({
          //   title: `An unknown error occurred, please try again or contact support@polymer.co`,
          //   kind: "warning",
          //   delay: 5000,
          // });
        },
      },
    );
  };

  const handleChangeStage = (name, value) => {
    setStageId(value);
  };

  /* MOVE FOCUS TO FIRST REMAINING JOB APPLICATION
  --===================================================-- */
  const moveFocusToFirstRemainingJobApplication = () => {
    window.logger("%c[BulkMoveModal] moveFocusToFirstRemainingJobApplication", "background-color: #19d232", {
      firstRemainingJobApplicationUrl,
    });

    if (firstRemainingJobApplicationUrl) {
      history.push(firstRemainingJobApplicationUrl);
    }
  };

  const modalButtons = (
    <Styled.ButtonContainer>
      <Button
        className="submit-button"
        type="submit"
        size="small"
        onClick={handleOnConfirm}
        loading={isLoadingMoveBulkJobApplicationsToStage}
        disabled={isLoadingMoveBulkJobApplicationsToStage || candidatesCount === 0}
        aria-label="Move stage"
      >
        Move
      </Button>
      <Button styleType="secondary" onClick={handleOnCancel}>
        Cancel
      </Button>
    </Styled.ButtonContainer>
  );

  const selectHiringStageValue = () => {
    const stage = hiringStageOptions.find((stage) => stage.value === stageId);
    return (stage && stage.value) || null;
  };

  return (
    <CenterModal headerTitleText={`Move candidates`} onCancel={handleOnCancel}>
      <Styled.Instructions>
        {candidatesCount === 0 ? (
          <p>
            <span>No candidates selected.</span> Use the checkboxes next to the candidate names to select the candidates to move. To <b>select all candidates</b> within this hiring stage, use the checkbox next to the stage name.
          </p>
        ) : (
          <p>
            You are moving <b>{candidatesCount}</b> candidate {candidatesCount === 1 ? "" : "s"} from the <b>{currentStage.name}</b> stage. Select the hiring stage to move them to.
          </p>
        )}
      </Styled.Instructions>
      <FormContainer errors={errors} buttons={modalButtons} onSubmit={handleOnConfirm}>
        <FormSelect
          onChange={handleChangeStage}
          name="jobStage"
          label="Hiring stage"
          description={null}
          className=""
          value={selectHiringStageValue()}
          options={hiringStageOptions}
          errors={errors}
          isClearable={false}
          autoFocus
        />
      </FormContainer>
    </CenterModal>
  );
}

export default BulkMoveModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: BulkMoveModal_Instructions;
    ${[t.mb(5)]}

    span {
      font-weight: bold;
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: BulkMoveModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});
