/* HOW TO USE

import { FeatureFlipper, useFeatureFlipper } from "@ats/src/components/shared/FeatureFlipper";

const isEnabled = useFeatureFlipper();
if (isEnabled({ feature: "CUSTOM_DOMAINS" })) {
  // do this
}

<FeatureFlipper feature="CUSTOM_DOMAINS">
  <p>Show Me</p>
</FeatureFlipper>


NOTES

useFeatureFlipperStore gets populated by hitting the mounted Flipper (gem) API. This API hits the local DB not a third party API.
The local DB is kept up to date via webhooks from flippercloud.io

This component is for Feature Flag management while the AuthorizationManager is for managing user roles and access

--===================================================-- */

import React from "react";
import { useFeatureFlipperStore } from "@ats/src/lib/store/zustand/featureFlipperStore";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";

/* HELPERS
--===================================================-- */
const gateBoolean = (feature) => {
  const gate = feature.gates.find((g) => g.key === "boolean");
  if (gate.value == undefined) {
    return false;
  }

  return gate.value;
};

const gateActors = (feature, organization) => {
  const gate = feature.gates.find((g) => g.key === "actors");
  if (gate.value.length === 0) {
    return false;
  }

  return gate.value.includes(organization.flipperId);
};

const gateGroups = (feature, organization) => {
  const gate = feature.gates.find((g) => g.key === "groups");
  if (gate.value.length === 0) {
    return false;
  }

  return gate.value.includes(organization.flipperGroup);
};

type Props = {
  feature: string;
  children: any;
};

/* COMPONENT
--===================================================-- */
function FeatureFlipper({ feature, children }: Props) {
  const { currentOrganization } = useCurrentSession();
  const features = useFeatureFlipperStore((state) => state.features);
  const featureObj = features.find((feat) => feat.key === feature);

  if (featureObj == undefined) {
    return null;
  }

  window.logger("%c[FeatureFlipper] RENDER ", "background-color: #FF76D2", {
    feature,
    features,
    featureObj,
    gateBoolean: gateBoolean(featureObj),
    gateActors: gateActors(featureObj, currentOrganization),
    gateGroups: gateGroups(featureObj, currentOrganization),
  });

  if (
    gateBoolean(featureObj) ||
    gateActors(featureObj, currentOrganization) ||
    gateGroups(featureObj, currentOrganization)
  ) {
    return <>{children}</>;
  }

  return null;
}

/* HOOK
--===================================================-- */
function useFeatureFlipper(): (args: { feature: string }) => boolean {
  const { currentOrganization } = useCurrentSession();
  const features = useFeatureFlipperStore((state) => state.features);

  return ({ feature }): boolean => {
    const featureObj = features.find((feat) => feat.key === feature);
    return (
      featureObj !== undefined &&
      (gateBoolean(featureObj) ||
        gateActors(featureObj, currentOrganization) ||
        gateGroups(featureObj, currentOrganization))
    );
  };
}

export { FeatureFlipper, useFeatureFlipper };

export enum Features {
  CUSTOM_DOMAINS = "CUSTOM_DOMAINS",
  NOTIFICATION_SUMMARY_COUNTS = "NOTIFICATION_SUMMARY_COUNTS",
  PLAN_BASED_BILLING = "PLAN_BASED_BILLING",
  ANONYMIZE_CANDIDATES = "ANONYMIZE_CANDIDATES",
  DISCORD_INTEGRATION = "DISCORD_INTEGRATION",
  SLACK_INTEGRATION = "SLACK_INTEGRATION",
  LINKEDIN_INTEGRATION = "LINKEDIN_INTEGRATION",
  ZAPIER_INTEGRATION = "ZAPIER_INTEGRATION",
  MVP_REPORTING = "MVP_REPORTING",
  X_HIRING_INTEGRATION = "X_HIRING_INTEGRATION",
}
