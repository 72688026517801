import { useQuery, useMutation, useQueryClient } from "react-query";
import { apiGet, apiPost, apiDelete } from "@shared/queryHooks/api";

const login = async ({ email, password }) => {
  return await apiPost({
    path: `/login`,
    variables: { api_v1_user: { email, password, remember_me: 1 } },
    skipKeysToSnake: true,
  });
};

const logout = async () => {
  return await apiDelete({ path: "/logout", variables: null });
};

const sendPasswordResetEmail = async ({ email }) => {
  return await apiPost({ path: `/send_password_reset`, variables: { email } });
};

const resetPassword = async ({ password, passwordConfirmation, resetPasswordToken }) => {
  return await apiPost({
    path: `/reset_password`,
    variables: { password, passwordConfirmation, resetPasswordToken },
  });
};

const register = async ({ firstName, lastName, email, password, inviteToken, referral }) => {
  return await apiPost({
    path: `/sign_up`,
    variables: {
      firstName,
      lastName,
      email,
      password,
      inviteToken,
      referral,
    },
  });
};

const magicLink = async ({
  email,
  loginIntent,
  organizationSlug,
  inviteToken,
  redirectTo,
  referral,
}: {
  email: string;
  loginIntent: string;
  organizationSlug?: string | null;
  inviteToken?: string | null;
  redirectTo?: string | null;
  referral?: string | null;
}) => {
  window.logger("%c[useSession] magicLink", "color: #1976D2", {
    email,
    redirectTo,
    organizationSlug,
    loginIntent,
  });
  return await apiPost({
    path: `/magic_login`,
    variables: {
      // fullName,
      email,
      organizationSlug,
      inviteToken,
      redirectTo,
      referral,
      loginIntent,
    },
  });
};

const getMagicLinkStatusByEmailHash = async ({ emailHash }) => {
  window.logger("%c[useSession] emailHash", "color: #1976D2", { emailHash });
  return await apiGet({ path: `/magic_link_status/${emailHash}` });
};

/* HOOKS
--===================================================-- */

export function useLogin() {
  const queryClient = useQueryClient();
  return useMutation(login, {
    onSuccess: (data) => {
      window.logger("%c[useSession] useLogin onSuccess", "color: #1976D2", {
        data,
      });
      queryClient.setQueryData("me", data);
    },
    // throwOnError: true,
  });
}

export function useLogout() {
  return useMutation(logout, {
    onSuccess: (data) => {
      window.logger("%c[useSession] useLogout onSuccess", "color: #1976D2", {
        data,
      });
    },
  });
}

export function useSendPasswordResetEmail() {
  const queryClient = useQueryClient();
  return useMutation(sendPasswordResetEmail, {
    onSuccess: (data) => {
      window.logger("%c[useSession] useSendPasswordResetEmail onSuccess", "color: #1976D2", {
        data,
      });
      // queryClient.setQueryData("me", data);
    },
    // throwOnError: true,
  });
}

export function useResetPassword() {
  const queryClient = useQueryClient();
  return useMutation(resetPassword, {
    onSuccess: (data) => {
      window.logger("%c[useSession] useResetPassword onSuccess", "color: #1976D2", {
        data,
      });
      // queryClient.setQueryData("me", data);
    },
    // throwOnError: true,
  });
}

export function useRegister() {
  const queryClient = useQueryClient();
  return useMutation(register, {
    onSuccess: (data) => {
      window.logger("%c[useSession] useLogin onSuccess", "color: #1976D2", {
        data,
      });
      queryClient.setQueryData("me", data);
    },
    // throwOnError: true,
  });
}

export function useMagicLink() {
  const queryClient = useQueryClient();
  return useMutation(magicLink, {
    onSuccess: (data) => {
      window.logger("%c[useSession] useMagicLink onSuccess", "color: #1976D2", {
        ...data,
      });
      // queryClient.setQueryData("me", data);
      queryClient.invalidateQueries(["me"]);
    },
    // throwOnError: true,
  });
}

export function useGetMagicLinkStatusByEmailHash(emailHash) {
  // const queryClient = useQueryClient();
  return useQuery(
    ["magicLinkStatus", emailHash],
    () => getMagicLinkStatusByEmailHash({ emailHash }),
    {
      // retry: (failureCount, error) =>
      //   !error.message.includes(401) && !error.message.includes(403) && !error.message.includes(422),
    },
  );
}
