import axios from "axios";
import { allKeysToSnake, allKeysToCamel } from "@ats/src/lib/utils/structure";
import Rails from "@rails/ujs";

export const apiGet = async ({ path }) => {
  const requestPath = `/api/v1${path}`;

  const { data } = await axios.get(requestPath, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  window.logger("%c[queryHooks] apiGet", "color: #1976D2", {
    data,
    requestPath,
    // zustandAuthToken,
    // contextAuthToken,
  });

  return allKeysToCamel(data);
};

export const apiPost = async ({ path, variables, skipKeysToSnake = false }) => {
  window.logger("%c[queryHooks] apiPost", "color: #109902", { path, variables });
  return await apiMutate({ method: "post", path, variables, skipKeysToSnake });
};

export const apiPut = async ({ path, variables }) => {
  window.logger("%c[queryHooks] apiPut", "color: #99F002; background: black;", { path, variables });
  return await apiMutate({ method: "put", path, variables });
};

const apiMutate = async ({ method, path, variables, skipKeysToSnake = false }) => {
  const requestPath = `/api/v1${path}`;

  const { data } = await axios
    .request({
      method,
      url: requestPath,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      data: skipKeysToSnake ? variables : allKeysToSnake(variables),
    })
    .catch((errorObject) => {
      const { response } = errorObject;
      const responseData = allKeysToCamel(response.data);
      const normalizedError = {
        ...response,
        data: responseData,
      };
      window.logger("%c[apiMutate] catch ERROR", "color: #FF0072", normalizedError);
      return Promise.reject(normalizedError);
    });

  window.logger("%c[queryHooks] apiMutate", "color: #1976D2", { data, variables, requestPath });

  return allKeysToCamel(data);
};

/* DELETE
--===================================================-- */
export const apiDelete = async ({ path, variables }) => {
  const requestPath = `/api/v1${path}`;

  const { data } = await axios.request({
    method: "delete",
    url: requestPath,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: allKeysToSnake(variables),
  });

  window.logger("%c[queryHooks] apiDelete", "color: #1976D2", { data, variables, requestPath });

  return allKeysToCamel(data);
};
