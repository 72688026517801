import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";
import FormContainer from "@ats/src/components/forms/FormContainer";
import FormSelect from "@ats/src/components/forms/FormSelect";

import { useModalContext } from "@shared/context/ModalContext";

type HiringStage = {
  name: string;
  id: number;
  kind: string;
  jobId: number;
};

type Props = {
  title: string;
  subcopy?: string;
  onConfirm: (stageId: number) => void;
  onCancel: ({ }) => void;
  currentStage: HiringStage;
  nextLogicalHiringStage?: HiringStage;
  hiringStages: Array<HiringStage>;
};

function JobApplicationStageMoveModal(props: Props) {
  const {
    title,
    subcopy,
    onConfirm,
    onCancel,
    currentStage,
    nextLogicalHiringStage,
    hiringStages,
  } = props;
  const [errors, setErrors] = React.useState([]);
  const [stageId, setStageId] = React.useState(
    nextLogicalHiringStage ? nextLogicalHiringStage.id : currentStage.id,
  );
  const [isDirty, setIsDirty] = React.useState(false);
  const { dismissModalWithAnimation } = useModalContext();
  const hiringStageOptions = hiringStages
    ? hiringStages
      .filter((stage) => stage !== currentStage && stage.kind !== "kind_archived")
      .map((hiringStage) => ({ value: hiringStage.id, label: hiringStage.name }))
    : [];

  const handleOnCancel = (event) => {
    if (event != undefined) {
      event.preventDefault();
    }
    dismissModalWithAnimation(() => onCancel);
  };

  const handleOnConfirm = (event) => {
    event.preventDefault();
    dismissModalWithAnimation(() => handleConfirmation); // have to call the function like this so that onCofirm does not run immediately
  };

  const handleConfirmation = () => {
    window.logger(
      "%c[JobApplicationStageMoveModal] handleConfirmation",
      "background-color: #197600; color: #FFF;",
      {
        stageId,
      },
    );
    onConfirm(stageId);
  };

  const handleChangeStage = (name, value) => {
    setStageId(value);
    setIsDirty(true);
  };

  const modalButtons = (
    <Styled.ButtonContainer>
      <Button
        className="submit-button"
        type="submit"
        size="small"
        onClick={handleOnConfirm}
        // loading={isLoadingStageUpdate}
        aria-label="Move stage"
      >
        Move
      </Button>
      <Button styleType="secondary" onClick={handleOnCancel}>
        Cancel
      </Button>
    </Styled.ButtonContainer>
  );

  const selectHiringStageValue = () => {
    const stage = hiringStageOptions.find((stage) => stage.value === stageId);
    return (stage && stage.value) || null;
  };

  return (
    <CenterModal headerTitleText={title} onCancel={handleOnCancel} hasUnsavedChanges={isDirty}>
      {subcopy && <Styled.Instructions>{subcopy}</Styled.Instructions>}
      <FormContainer errors={errors} buttons={modalButtons} onSubmit={handleOnConfirm}>
        <FormSelect
          onChange={handleChangeStage}
          name="jobStage"
          label="Hiring stage"
          description={null}
          className=""
          value={selectHiringStageValue()}
          options={hiringStageOptions}
          errors={errors}
          isClearable={false}
          autoFocus
        />
      </FormContainer>
    </CenterModal>
  );
}

export default JobApplicationStageMoveModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationStageMoveModal_Instructions;
    ${[t.mb(5)]}
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationStageMoveModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});
