import React, { Component } from "react";
import PropTypes from "prop-types";
import Joi from "joi-browser";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";
import FormContainer from "@ats/src/components/forms/FormContainer";
import FormInput from "@ats/src/components/forms/FormInput";
import FormSelect from "@ats/src/components/forms/FormSelect";
import FormSelectBuilder from "@ats/src/components/forms/FormSelectBuilder";
import Tooltip from "@ats/src/components/shared/Tooltip";

import { jobSettingOptions, questionKindOptions } from "@ats/src/lib/lookups";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

class CustomQuestionModal extends Component {
  static displayName = "CustomQuestionModal";

  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    isEditing: PropTypes.bool,
    index: PropTypes.number,
  };

  static defaultProps = {
    // key: value,
  };

  state = {
    questionText: "",
    kind: "text_short",
    requirementSetting: "required",
    errors: null,
    selectBuilderOptions: [],
  };

  constructor(props) {
    super(props);

    if (props.isEditing) {
      const selectBuilderOptions = props.question.options.data;
      const nextState = Object.assign({}, this.state, props.question);
      this.state = Object.assign({}, nextState, { selectBuilderOptions });
      // window.logger("%c[CustomQuestionModal] constructor", "color: #F906DF", {
      //   props,
      //   state: this.state,
      //   selectBuilderOptions,
      // });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const {
      id,
      questionText,
      kind,
      requirementSetting,
      position,
      selectBuilderOptions,
    } = this.state;

    const schema = Joi.object().keys({
      questionText: Joi.string().required(),
      answerChoices:
        kind === "single_select" || kind === "multi_select"
          ? Joi.array().min(2).required()
          : Joi.array().required(),
      // department: Joi.string().min(2).required(),
    });

    const question = {
      id,
      questionText,
      kind,
      requirementSetting,
      position,
      options: { data: selectBuilderOptions },
    };

    const answerChoices = selectBuilderOptions;

    if (this.props.onSubmit) {
      Joi.validate({ questionText, answerChoices }, schema, (err) => {
        if (err !== null) {
          window.logger("%c[CQM] Joi validation", "color: #1976D2", { err });

          // this.setState({
          //   errors: {
          //     questionText: ["cannot be blank"],
          //     options: ["needs at least 2 options"],
          //   },
          // });
          this.setState({
            errors: err.details,
          });
        } else {
          // window.logger('%c[CustomQuestionModal] handleSubmit is good to go', 'color: #1976D2');
          this.props.onSubmit(question, this.props.isEditing);
        }
      });
    }
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value, isDirty: true, errors: null })
  }

  handleUpdateSelectBuilderOptions = (options) => {
    window.logger("%c[CustomQuestionModal] handleUpdateSelectBuilderOptions", "color: #F976D2", {
      options,
    });
    this.handleChange("selectBuilderOptions", options);
  };

  render() {
    // window.logger('%c[CustomQuestionModal] render this.state', 'color: #1976D2', this.state);
    // window.logger("%c[CustomQuestionModal] render this.props", "color: #1976D2", this.props);

    const { isEditing, onCancel } = this.props;
    const {
      questionText,
      kind,
      requirementSetting,
      errors,
      selectBuilderOptions,
      isDirty,
    } = this.state;
    const modalTitle = isEditing ? "Edit custom question" : "New custom question";
    const confirmButtonText = isEditing ? "Update question" : "Create question";
    const questionTypeDescription =
      kind === "file_upload" ? "Maximum of 10 questions of type file upload." : "";

    const buttons = (
      <Styled.ButtonContainer>
        <Button onClick={this.handleSubmit} type="button">
          {confirmButtonText}
        </Button>
        <Button styleType="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </Styled.ButtonContainer>
    );

    return (
      <CenterModal headerTitleText={modalTitle} onCancel={onCancel} hasUnsavedChanges={isDirty}>
        <Styled.Instructions>
          Set the question text, the question type, and if an answer is required.
        </Styled.Instructions>
        <FormContainer errors={errors} buttons={buttons}>
          <FormInput
            onChange={this.handleChange}
            name="questionText"
            placeholder=""
            label="Question text"
            value={questionText}
            errors={errors}
          />

          <FormSelect
            onChange={this.handleChange}
            name="kind"
            label="Question type"
            description={questionTypeDescription}
            className=""
            value={kind}
            options={questionKindOptions}
            errors={errors}
          />

          {(kind === "single_select" || kind === "multi_select") && (
            <FormSelectBuilder
              options={selectBuilderOptions}
              updateOptions={this.handleUpdateSelectBuilderOptions}
            />
          )}

          {kind === "file_upload" && (
            <Styled.Info>
              <span>Candidate file uploads have size and file type restrictions.</span>
              <Tooltip label="Maximum size of 10MB. Allowed file types are docx, .pdf, .csv, .xls, .xlsx, .txt, .jpeg, .png, .svg, .mp3, .wav, and .ogg" />
            </Styled.Info>
          )}

          <FormSelect
            onChange={this.handleChange}
            name="requirementSetting"
            label="Question requirement"
            description="Is an answer required to submit the application?"
            className=""
            value={requirementSetting}
            options={jobSettingOptions.filter((option) => option.value !== "hidden")}
            errors={errors}
          />
        </FormContainer>
      </CenterModal>
    );
  }
}

export default CustomQuestionModal;

/* Styled Components
======================================================= */
const Styled = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: CustomQuestionModal_Instructions;
    ${[t.mb(5)]}
  `;
});

Styled.Info = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: CustomQuestionModal_Info;
    ${[t.text.xs, t.mt(-1), t.mb(5)]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    display: flex;
    align-items: center;

    > div {
      ${[t.ml(1)]};
    }
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: CustomQuestionModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});
