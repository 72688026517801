import React from "react";

import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";
import { useToastContext } from "@shared/context/ToastContext";
import { useUpdateOrganization } from "@shared/queryHooks/useOrganization";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import UnsavedChangesGuard from "@ats/src/components/shared/UnsavedChangesGuard";
import Button from "@ats/src/components/shared/Button";
import Icon from "@ats/src/components/shared/Icon";
import FormSection from "@ats/src/components/forms/FormSection";
import FormInput from "@ats/src/components/forms/FormInput";
import LinkedinIdConfirmationModal from "@ats/src/components/modals/LinkedinIdConfirmationModal";

import { useModalContext } from "@shared/context/ModalContext";

function AccountIntegrationsLinkedIn(props) {
  const addToast = useToastContext();
  const { openModal, removeModal } = useModalContext();
  const [isDirty, setIsDirty] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const { currentOrganization } = useCurrentSession();
  const [companyId, setCompanyId] = React.useState(
    currentOrganization.linkedinCompanyId ? currentOrganization.linkedinCompanyId : "",
  );

  const {
    mutate: updateOrganization,
    isLoading: isLoadingUpdateOrganization,
  } = useUpdateOrganization();

  window.logger("%c[AccountIntegrationsLinkedIn] render", "color: #1976D2", { props, companyId, errors });

  const onChangeCompanyId = (name, value) => {
    // window.logger(
    //   "%c[AccountIntegrationsLinkedIn] state change",
    //   "color: #1976D2",
    //   value,
    //   companyId
    // );
    setErrors({});
    setIsDirty(true);
    setCompanyId(value);
  };

  const onClickSubmit = async (e) => {
    e.preventDefault();

    const modal = (
      <LinkedinIdConfirmationModal
        onConfirm={handleSubmit}
        onCancel={removeModal}
        linkedInCompanyId={companyId}
      />
    );

    if (companyId === "") {
      handleSubmit();
    } else {
      openModal(modal);
    }
  };

  const handleSubmit = async () => {
    updateOrganization(
      { id: currentOrganization.id, linkedinCompanyId: companyId },
      {
        onSuccess: () => {
          setIsDirty(false);
          addToast({
            title: `Successfully updated`,
            kind: "success",
          });
          removeModal();
        },
        onError: (response: any) => {
          window.logger(
            "%c[AccountIntegrationsLinkedIn] Error OnSubmit",
            "color: #1976D2",
            { response, message: response.data.errors }
          );
          const message = response.data.errors.linkedinCompanyId[0];
          addToast({
            title: `LinkedIn company id ${message}`,
            kind: "danger",
            delay: 5000
          });
        },
      },
    );
  };

  const BottomBar = (
    <>
      <UnsavedChangesGuard
        hasUnsavedChanges={isDirty}
        navigate={(pathname) => {
          window.logger(
            "%c[AccountIntegrationsLinkedIn] UnsavedChangesGuard navigate",
            "color: #1976D2",
            pathname,
          );
          setIsDirty(false);
          props.history.push(pathname);
        }}
      />

      <Button
        className="submit-button"
        type="button"
        size="small"
        onClick={onClickSubmit}
        loading={isLoadingUpdateOrganization}
        aria-label="Save changes"
      >
        Save changes
      </Button>
    </>
  );
  
  const description = (
    <>
      For help finding your LinkedIn Company ID{"  "}
      <a
        href="https://www.linkedin.com/help/linkedin/answer/a415420"
        target="_blank"
        rel="noreferrer noopener"
      >
        view the LinkedIn help docs.
      </a>
    </>
  );

  const ViewLink = (
    <>
      <Button
        type="externalLink"
        link="https://help.polymer.co/en/articles/8828635-post-your-jobs-to-linkedin"
        styleType="text"
      >
        View docs
        <Icon name="external-link" />
      </Button>
    </>
  );

  return (
    <SettingsContainer
      title="LinkedIn Limited Listings"
      description="Publish jobs to LinkedIn for free using LinkedIn Limited Listings. Just add your LinkedIn company id below to opt in."
      bottomBar={BottomBar}
      fullWidthForm={true}
      actions={ViewLink}
    >
      <FormSection>
        <FormInput
          name="companyId"
          onChange={onChangeCompanyId}
          label="LinkedIn Company ID"
          description={description}
          value={companyId}
          errors={errors}
          inputType="number"
          // disabled={true}
        />
      </FormSection>
    </SettingsContainer>
  );
}

export default AccountIntegrationsLinkedIn;
