import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import FormSection from "@ats/src/components/forms/FormSection";
import FormSelect from "@ats/src/components/forms/FormSelect";
import ProseMirrorEditor from "@shared/ProseMirror/Editor";
import Button from "@ats/src/components/shared/Button";
import { useToastContext } from "@shared/context/ToastContext";
import { useUpdateJob } from "@shared/queryHooks/useJob";
import FormConditionalFields from "@ats/src/components/forms/FormConditionalFields";
import { errorTextViaYupOrRailsOrJoi } from "@shared/lib/formHelpers";

function JobSetupAutomations(props) {
  const { setIsDirty, job: passedJob } = props;
  const [job, setJob] = useState({
    useApplyResponseTemplate: passedJob.useApplyResponseTemplate,
    applyResponseTemplate: passedJob.applyResponseTemplate,
  });
  const [errors, setErrors] = useState(null);
  const editorRef = React.useRef(undefined);
  const { useApplyResponseTemplate, applyResponseTemplate } = job;
  const addToast = useToastContext();
  const { mutate: updateJob, isLoading: isLoadingUpdateJob } = useUpdateJob();

  const onFormInputChange = (name, value) => {
    window.logger("%c[JobSetupAutomations] onFormInputChange", "background-color: #5eb65b", {
      name,
      value,
    });

    setIsDirty(true);
    if (errors != undefined) {
      setErrors(null);
    }

    setJob({ ...job, [name]: value });
  };

  const handleChangeEditorState = () => {
    setErrors([]);
    setIsDirty(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // Submit the form here
    console.warn("[JobSetupAutomations] onSubmit { job }", { job });

    updateJob(
      {
        id: passedJob.id,
        useApplyResponseTemplate,
        applyResponseTemplate: editorRef.current
          ? editorRef.current.serializedState()
          : applyResponseTemplate,
      },
      {
        onSuccess: () => {
          setIsDirty(false);
          setErrors(null);
          addToast({
            title: `Job updated`,
            kind: "success",
          });
        },
        onError: (response: any) => {
          window.logger("%c[JobSetupAutomations] updateJob onError", "color: #F97602", {
            response,
          });
          setErrors(response.data.errors);
          addToast({
            title: `Could not update job`,
            kind: "warning",
          });
        },
      },
    );
  };

  const applyResponseTemplateError = errorTextViaYupOrRailsOrJoi({
    errors,
    name: "applyResponseTemplate",
  });

  const BottomBarContent = (
    <Button
      className="submit-button"
      type="button"
      size="small"
      onClick={onSubmit}
      loading={isLoadingUpdateJob}
      aria-label="Save changes"
    >
      Save changes
    </Button>
  );

  return (
    <>
      <SettingsContainer
        title="Automations"
        description="Here's where you can configure automations to save time during the hiring process."
        bottomBar={BottomBarContent}
      >
        <FormSection title="Application confirmation">
          <FormSelect
            onChange={onFormInputChange}
            name="useApplyResponseTemplate"
            label="Custom confirmation message"
            description="Automatically send a message to new candidates when an application is received. If enabled, your message will be sent instead of the default confirmation from Polymer."
            className=""
            value={useApplyResponseTemplate}
            options={[
              { label: "Disabled", value: false },
              { label: "Enabled", value: true },
            ]}
            errors={errors}
          />

          <Styled.Template isDisabled={!useApplyResponseTemplate}>
            <FormConditionalFields>
              <Styled.TemplateInput>
                <ProseMirrorEditor
                  ref={editorRef}
                  enableLabel
                  label="Message template"
                  editorId="templates-editor"
                  enableStaticMenuBar={false}
                  enableSelectionToolbar={true}
                  enableMessageMailMergeMenuBar={true}
                  allowSenderMailMergeFields={false}
                  enableMessageKeys={true}
                  onChange={handleChangeEditorState}
                  defaultValue={applyResponseTemplate || ""}
                />
              </Styled.TemplateInput>
              {applyResponseTemplateError && (
                <Styled.Error>{applyResponseTemplateError}</Styled.Error>
              )}
            </FormConditionalFields>
          </Styled.Template>
        </FormSection>
      </SettingsContainer>
    </>
  );
}

export default JobSetupAutomations;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Template = styled.div<{ isDisabled: boolean }>((props) => {
  const t: any = props.theme;
  return css`
    label: JobSetupAutomations_Template;
    display: ${props.isDisabled ? "none" : "block"};
  `;
});

Styled.TemplateInput = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ChannelMessageTemplateModal_TemplateInput;
    > div {
      ${[t.mb(0)]}
    }
    textarea {
      min-height: 12rem;
    }
  `;
});

Styled.Error = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobSetupAutomations_Error;
    ${t.text.sm}
    color: ${t.dark ? t.color.red[400] : t.color.red[600]};
    ${[t.mt(3)]}
    ${t.mq["md"]} {
      ${[t.text.xs]}
    }
  `;
});
