import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import Icon from "@ats/src/components/shared/Icon";
import Tooltip from "@ats/src/components/shared/Tooltip";

import { prettyDateAndTime } from "@shared/lib/time";

const eventHtml = (iconName: string, action: string, timestamp, timeAgoShort) => {
  return (
    <Styled.Event>
      <Styled.Icon>
        <Icon name={iconName} />
      </Styled.Icon>
      {action}
      <Styled.Seperator>‧</Styled.Seperator>
      <Tooltip label={prettyDateAndTime(timestamp)}>
        <span>{timeAgoShort}</span>
      </Tooltip>
    </Styled.Event>
  );
};

function ActivityListItem({ activityKey, parameters, createdAtTimestamp, createdAtTimeAgoShort }) {
  const userName = parameters.user ? parameters.user.fullName : "Unknown";
  switch (activityKey) {
    case "job_application.moved_stage":
      if (parameters.hiringStageName === "Archived") {
        const action = `${userName} archived candidate`;
        return eventHtml("archive", action, createdAtTimestamp, createdAtTimeAgoShort);
      } else {
        const action = `${userName} moved candidate to ${parameters.hiringStageName}`;
        const icon = parameters.hiringStageName === "Hired" ? "check-square" : "corner-right-down";
        return eventHtml(icon, action, createdAtTimestamp, createdAtTimeAgoShort);
      }

    case "candidate.requested_to_delete_data":
      return eventHtml(
        "alert-triangle",
        "Candidate requested to have data deleted",
        createdAtTimestamp,
        createdAtTimeAgoShort,
      );

    case "candidate.anonymized_data":
      return eventHtml(
        "user-x",
        `${parameters.user ? parameters.user.fullName : "Unknown"} deleted candidate data`,
        createdAtTimestamp,
        createdAtTimeAgoShort,
      );

    case "job_application.review_request":
      return eventHtml(
        "edit-3",
        `${
          parameters.requestingUser ? parameters.requestingUser.fullName : "Unknown"
        } requested a review from ${
          parameters.requestedUser ? parameters.requestedUser.fullName : "Unknown"
        }`,
        createdAtTimestamp,
        createdAtTimeAgoShort,
      );

    case "job_application.review_request_invite":
      return eventHtml(
        "edit-3",
        `${
          parameters.requestingUser ? parameters.requestingUser.fullName : "Unknown"
        } requested a review from ${
          parameters.invitedUserEmail ? parameters.invitedUserEmail : "Unknown"
        }`,
        createdAtTimestamp,
        createdAtTimeAgoShort,
      );

    // case "job_application.new":
    //   return (
    //     <Styled.Event>
    //       <Styled.Icon>
    //         <Icon name="inbox" />
    //       </Styled.Icon>
    //       Application received
    //       <Styled.Seperator>‧</Styled.Seperator>
    //       <Tooltip label={prettyDateAndTime(createdAtTimestamp)}>
    //         <span>{createdAtTimeAgoShort}</span>
    //       </Tooltip>
    //     </Styled.Event>
    //   );

    // case "job_application.new.manual_add":
    //   return (
    //     <Styled.Event>
    //       <Styled.Icon>
    //         <Icon name="user-plus" />
    //       </Styled.Icon>
    //       Manually added by {parameters.user ? parameters.user.fullName : "Unknown"}
    //       <Styled.Seperator>‧</Styled.Seperator>
    //       <Tooltip label={prettyDateAndTime(createdAtTimestamp)}>
    //         <span>{createdAtTimeAgoShort}</span>
    //       </Tooltip>
    //     </Styled.Event>
    //   );
    default:
      return null;
  }
}

export default ActivityListItem;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Event = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: ActivityListItem_Event;
    ${[t.ml(1), t.mb(3), t.text.xs]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: ${t.spacing[6]};
      top: 100%;
      width: 4px;
      margin-left: -2px;
      height: ${t.spacing[6]};
      background-color: ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    }
  `;
});

Styled.Icon = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ActivityListItem_Icon;
    ${[t.ml(3), t.mr(2), t.h(6), t.w(6), t.rounded.xs]}
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
  `;
});

Styled.Seperator = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: ActivityListItem_Seperator;
    ${t.mx(1)}
  `;
});
